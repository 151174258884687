import { TOS } from '../constants/cmsLinks';
import { AUDIO_PREROLL_INTERVAL_SECONDS } from '../constants/experiments/ads';
import {
  BOOST_INTRO_AUDIO,
  BOOST_INTRO_PLAYSESSIONFREQCAP_COUNT,
  BOOST_OUTRO_AUDIO,
  BOOST_OUTRO_PLAYSESSIONFREQCAP_COUNT,
  BOOST_TOOLTIP_DEVICEFREQCAP_COUNT,
  BOOST_TOOLTIP_ENABLED,
} from '../constants/experiments/boost';
import {
  BRAZE_CONTENT_CARDS_ENABLED,
  BRAZE_CONTENT_CARDS_REFRESH_DELAY_SECONDS,
  BRAZE_CONTENT_CARDS_REMOVE_DUPLICATES_ENABLED,
  BRAZE_IN_APP_MESSAGE_ENABLED,
  CURRENT_EULA_TYPE,
  CURRENT_EULA_VERSION,
  TERMS_OF_SERVICE_LINK,
} from '../constants/experiments/braze';
import { DISABLE_LANDING_PRODUCT_FETCH } from '../constants/experiments/config';
import { DESKTOP_NOW_PLAYING_PAGE_ENABLED } from '../constants/experiments/desktop';
import { UNIFIED_EVENTS_CONTENT_ENABLED } from '../constants/experiments/reporting';
import {
  COUPON_CACHE_DISABLED,
  DEFAULT_RENEW_PRODUCT_OVERRIDES,
  DEFAULT_SUBSCRIBE_PRODUCT_OVERRIDES,
  RECURLY_COUPON_PREFIXES,
  SUBSCRIPTION_ENABLED,
  SUBSCRIPTION_SUCCESS_PARTNER_CONFIG,
} from '../constants/experiments/subscription';
import isDesktop from '../utils/desktop/isDesktop';

const NAMESPACE = 'config';

export const selectExperiment = (state, key, defaultValue) =>
  state?.[NAMESPACE]?.autoupdate?.[key] ?? defaultValue;
export const selectIsDesktopNowPlayingEnabled = (state) =>
  isDesktop() &&
  selectExperiment(state, DESKTOP_NOW_PLAYING_PAGE_ENABLED, false);
export const selectIsBrazeEnabled = (state) =>
  selectExperiment(state, BRAZE_IN_APP_MESSAGE_ENABLED, false);
export const selectIsBrazeContentCardsEnabled = (state) =>
  selectExperiment(state, BRAZE_CONTENT_CARDS_ENABLED, false);
export const selectIsBrazeContentCardsRemoveDuplicatesEnabled = (state) =>
  selectExperiment(state, BRAZE_CONTENT_CARDS_REMOVE_DUPLICATES_ENABLED, false);
export const selectBrazeContentCardsRefreshDelaySeconds = (state) =>
  selectExperiment(state, BRAZE_CONTENT_CARDS_REFRESH_DELAY_SECONDS, 5);
export const selectIsSubscriptionEnabled = (state) =>
  selectExperiment(state, SUBSCRIPTION_ENABLED, false);
export const selectIsLandingProductFetchDisabled = (state) =>
  selectExperiment(state, DISABLE_LANDING_PRODUCT_FETCH);
export const selectRecurlyCouponPrefixes = (state) =>
  selectExperiment(state, RECURLY_COUPON_PREFIXES, []);
export const selectAudioPrerollInterval = (state) =>
  selectExperiment(state, AUDIO_PREROLL_INTERVAL_SECONDS, 300);
export const selectAdsTargeting = (state) => state?.[NAMESPACE]?.ads?.targeting;
export const selectCountryId = (state) =>
  state?.[NAMESPACE]?.location?.country_id?.slice(1) || '';
export const selectCountryCode = (state) =>
  state?.[NAMESPACE]?.location?.country_code;
export const selectCountryStateCode = (state) =>
  state?.[NAMESPACE]?.location?.state;
export const selectLocation = (state) => state?.[NAMESPACE]?.location || {};
export const selectCouponCacheConfig = (state) =>
  selectExperiment(state, COUPON_CACHE_DISABLED, false);
export const selectDefaultSubscribeProductOverrides = (state) =>
  selectExperiment(state, DEFAULT_SUBSCRIBE_PRODUCT_OVERRIDES);
export const selectDefaultRenewProductOverrides = (state) =>
  selectExperiment(state, DEFAULT_RENEW_PRODUCT_OVERRIDES);
export const selectBoostTooltipEnabled = (state) =>
  selectExperiment(state, BOOST_TOOLTIP_ENABLED, false);
export const selectBoostIntroAudio = (state) =>
  selectExperiment(state, BOOST_INTRO_AUDIO, '');
export const selectBoostIntroPlaySessionCap = (state) =>
  selectExperiment(state, BOOST_INTRO_PLAYSESSIONFREQCAP_COUNT, 1);
export const selectBoostOutroAudio = (state) =>
  selectExperiment(state, BOOST_OUTRO_AUDIO, '');
export const selectBoostOutroPlaySessionCap = (state) =>
  selectExperiment(state, BOOST_OUTRO_PLAYSESSIONFREQCAP_COUNT, -1);
export const selectBoostTooltipCap = (state) =>
  selectExperiment(state, BOOST_TOOLTIP_DEVICEFREQCAP_COUNT, 1);
export const selectCurrentEulaVersion = (state) =>
  selectExperiment(state, CURRENT_EULA_VERSION, '');
export const selectCurrentEulaType = (state) =>
  selectExperiment(state, CURRENT_EULA_TYPE, '');
export const selectIsUnifiedEventsContentReportingEnabled = (state) =>
  selectExperiment(state, UNIFIED_EVENTS_CONTENT_ENABLED, false);
export const selectTermsOfServiceLink = (state) =>
  selectExperiment(state, TERMS_OF_SERVICE_LINK, TOS);
export const selectSubscriptionSuccessPartnerConfig = (state) =>
  selectExperiment(state, SUBSCRIPTION_SUCCESS_PARTNER_CONFIG, {});
