import cssVariables from 'src/common/styles/variables';
import AppConnector from '../components/AppConnector';
import RadioRouteConnector from '../components/RadioRouteConnector';
import AuthPageRouteConnector from '../components/auth/AuthPageRouteConnector';
import ResetPasswordConnector from '../components/auth/ResetPasswordConnector';
import OauthRouteConnector from '../components/auth/oauthProvider/OauthAuthConnector';
import OauthConfirmationRouteConnector from '../components/auth/oauthProvider/OauthConfirmationConnector';
import PinCodeConnector from '../components/auto/PinCodeConnector';
import RootCategoryConnector from '../components/browse/RootCategoryConnector';
import CouponLoader from '../components/coupon/CouponLoader';
import Error404 from '../components/errors/404';
import FavoritesConnector from '../components/favorites/FavoritesConnector';
import LandingConnector from '../components/landing/LandingConnector';
import MapViewConnector from '../components/mapView/MapViewConnector';
import PaymentCompletionConnector from '../components/payment/PaymentCompletionConnector';
import ProfileConnector from '../components/profile/ProfileConnector';
import SearchConnector from '../components/search/SearchConnector';
import AccountSettingsConnector from '../components/settings/AccountSettingsConnector';
import RenewRouteConnector from '../components/subscription/RenewRouteConnector';
import SubscribeRouteConnector from '../components/subscription/SubscribeRouteConnector';
import PremiumUpsellConnector from '../components/upsell/PremiumUpsellConnector';
import UserRouteConnector from '../components/user/UserRouteConnector';

import {
  ACCOUNT_DEVICES_PATH,
  ACCOUNT_NEW_RESET_PATH,
  ACCOUNT_PATH,
  ACCOUNT_PAYMENT_PATH,
  AUTHORIZE_CONFIRM_PATH,
  AUTHORIZE_PATH,
  AUTO_PATH,
  COUPON_PATH,
  LOGIN_PATH,
  MAP_VIEW,
  PAIR_PATH,
  PAYMENT_COMPLETE_PATH,
  PREMIUM_OFFER,
  RENEW_PATH,
  SUBSCRIBE_PAIR_PATH,
  SUBSCRIBE_PATH,
  UPDATE_PAYMENT_PATH,
  USER_FAVORITES_PATH,
  USER_PATH,
} from '../constants/paths';

import authViewTypes from '../constants/auth/viewTypes';

import {
  devicesTab,
  generalTab,
  subscriptionTab,
} from '../components/settings/getLinks';
import PaymentInformationRouteConnector from '../components/subscription/PaymentInformationRouteConnector';
import legacyRedirects from './legacyRedirects';
import { radioRouteValidator } from './utils/routeValidators';
import vanityRoutes from './vanityRoutes';

export default () => [
  {
    component: AppConnector,
    routes: [
      {
        path: '/',
        exact: true,
        component: LandingConnector,
        routeLevelProps: {
          isLanding: true, // needed on server for redirect
          isOverlayablePage: true,
          bgColor: 'white',
        },
      },
      {
        path: '/album/:guideIdentifier',
        component: ProfileConnector,
      },
      {
        path: '/artist/:guideIdentifier',
        component: ProfileConnector,
      },
      {
        path: '/radio/:guideIdentifier',
        component: RadioRouteConnector, // use if route is unclear if profile or browse
        routeLevelProps: {
          isOverlayablePage: true,
          routeValidator: radioRouteValidator,
        },
      },
      {
        path: '/desc/:guideIdentifier',
        component: RadioRouteConnector, // use if route is unclear if profile or browse
        routeLevelProps: {
          isOverlayablePage: true,
          routeValidator: radioRouteValidator,
          disableRedirects: true,
        },
      },
      {
        // it seems that there are podcast redirects already on the server
        path: '/podcasts/:podcastGenre/:podcastGuideIdentifier',
        component: RadioRouteConnector,
        routeLevelProps: {
          isOverlayablePage: true,
        },
      },
      {
        path: '/podcasts/:podcastGuideIdentifier',
        component: RadioRouteConnector,
        routeLevelProps: {
          isOverlayablePage: true,
        },
      },
      {
        path: '/podcasts',
        component: RootCategoryConnector,
        routeLevelProps: {
          isOverlayablePage: true,
        },
      },
      {
        path: '/audiobooks',
        component: RootCategoryConnector,
        routeLevelProps: {
          isOverlayablePage: true,
        },
      },
      {
        path: '/search/:seoMeta?',
        component: SearchConnector,
        routeLevelProps: {
          isSearchPage: true,
        },
      },
      {
        path: AUTHORIZE_PATH,
        component: OauthRouteConnector,
        exact: true,
        routeLevelProps: {
          disablePlayer: true,
          hideAds: true,
          hideLeft: true,
          hideRight: true,
          hideFooter: true,
          isMinimalHeader: true,
          unlinkedLogo: true,
          auth: {
            viewType: authViewTypes.signIn,
          },
          showErrorDialogOnly: true,
        },
      },
      {
        path: AUTHORIZE_CONFIRM_PATH,
        component: OauthConfirmationRouteConnector,
        exact: true,
        routeLevelProps: {
          disablePlayer: true,
          hideAds: true,
          hideLeft: true,
          hideRight: true,
          hideFooter: true,
          isMinimalHeader: true,
          unlinkedLogo: true,
          auth: {
            viewType: authViewTypes.signIn,
          },
          showErrorDialogOnly: true,
        },
      },
      {
        path: PREMIUM_OFFER,
        component: PremiumUpsellConnector,
        exact: true,
        routeLevelProps: {
          disablePlayer: true,
          hideAds: true,
          hideLeft: true,
          hideRight: true,
          hideFooter: true,
          isMinimalHeader: true,
          unlinkedLogo: true,
          restrictAuthLinksAndDialog: true,
          bgColor: cssVariables['--secondary-color-5'],
        },
      },
      {
        path: AUTO_PATH,
        component: PinCodeConnector,
        exact: true,
        routeLevelProps: {
          disablePlayer: true,
          hideAds: true,
          hideLeft: true,
          hideRight: true,
          hideFooter: true,
          isMinimalHeader: false,
          unlinkedLogo: true,
          restrictAuthLinksAndDialog: true,
        },
      },
      {
        path: PAIR_PATH,
        component: PinCodeConnector,
        exact: true,
        routeLevelProps: {
          disablePlayer: true,
          hideAds: true,
          hideLeft: true,
          hideRight: true,
          hideFooter: true,
          isMinimalHeader: false,
          unlinkedLogo: true,
          restrictAuthLinksAndDialog: true,
        },
      },
      {
        path: MAP_VIEW,
        component: MapViewConnector,
        exact: true,
        routeLevelProps: {
          isMapViewRoute: true,
          hideRight: true,
          hideFooter: true,
          hidePlayerOnMobile: true,
          showAuthAndAdDialogsOnly: true,
          hideLoader: true,
          noHeaderOnMobile: true,
          disablePreroll: true,
          disableScheduledRegistrationDialog: true,
        },
      },
      // legacyRedirects needs to come before
      // /account and /subscribe
      ...legacyRedirects,
      {
        path: LOGIN_PATH,
        component: AuthPageRouteConnector,
        routeLevelProps: {
          hideFooter: true,
          hideRight: true,
          restrictAuthLinksAndDialog: true,
        },
      },
      {
        path: ACCOUNT_NEW_RESET_PATH,
        component: ResetPasswordConnector,
        routeLevelProps: {
          hideRight: true,
          restrictAuthLinksAndDialog: true,
          auth: {
            viewType: authViewTypes.resetPassword,
          },
        },
      },
      {
        path: SUBSCRIBE_PATH,
        component: SubscribeRouteConnector,
        routeLevelProps: {
          hideFooter: true,
          hideRight: true,
          hideRightSideTillXxl: true,
        },
      },
      {
        path: SUBSCRIBE_PAIR_PATH,
        component: SubscribeRouteConnector,
        routeLevelProps: {
          disablePlayer: true,
          hideAds: true,
          hideLeft: true,
          hideRight: true,
          hideFooter: true,
          isMinimalHeader: false,
          unlinkedLogo: true,
          restrictAuthLinksAndDialog: true,
        },
      },
      {
        path: RENEW_PATH,
        component: RenewRouteConnector,
        routeLevelProps: {
          hideFooter: true,
          hideRight: true,
          hideRightSideTillXxl: true,
        },
      },
      {
        path: UPDATE_PAYMENT_PATH,
        component: PaymentInformationRouteConnector,
        routeLevelProps: {
          hideFooter: true,
          hideRight: true,
          hideRightSideTillXxl: true,
        },
      },
      {
        path: ACCOUNT_PATH,
        component: AccountSettingsConnector,
        routeLevelProps: {
          hideRightSidebarUpsell: true,
          settingsTab: generalTab,
          auth: {
            viewType: authViewTypes.signIn,
          },
        },
      },
      {
        path: ACCOUNT_DEVICES_PATH,
        component: AccountSettingsConnector,
        routeLevelProps: {
          hideRightSidebarUpsell: true,
          settingsTab: devicesTab,
          auth: {
            viewType: authViewTypes.signIn,
          },
        },
      },
      {
        path: ACCOUNT_PAYMENT_PATH,
        component: AccountSettingsConnector,
        routeLevelProps: {
          hideRightSidebarUpsell: true,
          settingsTab: subscriptionTab,
          auth: {
            viewType: authViewTypes.signIn,
          },
        },
      },
      {
        path: PAYMENT_COMPLETE_PATH,
        component: PaymentCompletionConnector,
        routeLevelProps: {
          hideFooter: true,
          hideRight: true,
          hideRightSideTillXxl: true,
        },
      },
      {
        path: COUPON_PATH,
        component: CouponLoader,
        routeLevelProps: {
          noHeader: true,
          bgColor: 'white',
          hideLeft: true,
          hideRight: true,
          hideFooter: true,
          hideAds: true,
          restrictAuthLinksAndDialog: true,
        },
      },
      {
        path: USER_PATH,
        component: UserRouteConnector,
        exact: true,
        routeLevelProps: {
          isUserPage: true,
        },
      },
      {
        path: USER_FAVORITES_PATH,
        component: FavoritesConnector,
        routeLevelProps: {
          isUserPage: true,
        },
      },
      ...vanityRoutes,
      {
        path: '/*',
        component: Error404,
        routeLevelProps: {
          is404: true, // server check
        },
      },
    ],
  },
];
