const NAMESPACE = 'subscription';

export const selectSubProviderUserId = (state) =>
  state?.[NAMESPACE]?.subProviderInfo?.userInfo?.id;

export const selectSubProviderSetupIntentSecret = (state) =>
  state?.[NAMESPACE]?.subProviderInfo?.intentInfo?.clientSecret;

export const selectSubProviderSetupIntentId = (state) =>
  state?.[NAMESPACE]?.subProviderInfo?.intentInfo?.id;

export const selectCoupon = (state) => state?.[NAMESPACE]?.coupon;

export const selectHasCoupon = (state) => {
  const coupon = selectCoupon(state);
  return !!coupon && !coupon?.hasFailed && !coupon?.isFetching;
};

export const selectCurrentSubProductSku = (state) =>
  state?.[NAMESPACE]?.current?.productSku;

export const selectCurrentSubProductPriceId = (state) =>
  state?.[NAMESPACE]?.current?.productPriceId;

export const selectCurrentSubPendingCancellationStatus = (state) =>
  state?.[NAMESPACE]?.current?.isPendingCancellation;

export const selectCurrentSubStatus = (state) =>
  state?.[NAMESPACE]?.current?.status;

export const selectCurrentSubscription = (state) =>
  state?.[NAMESPACE]?.current || {};

export const selectCustomerAddress = (state) => state?.[NAMESPACE]?.address;
