const NAMESPACE = 'app';

export const selectIsFetching = (state) =>
  state[NAMESPACE]?.isFetching || false;
export const selectAllowAutoplay = (state) => state[NAMESPACE]?.allowAutoplay;
export const selectBranchUrl = (state) => state[NAMESPACE]?.branchUrl;
export const selectBreakpoint = (state) => state[NAMESPACE]?.breakpoint;
export const selectIsDesktop = (state) => state[NAMESPACE]?.isDesktop || false;
export const selectDesktopOs = (state) => state[NAMESPACE]?.desktopOS;
export const selectDesktopVersion = (state) => state[NAMESPACE]?.desktopVersion;
export const selectIsMobile = (state) => state[NAMESPACE]?.isMobile || false;
export const selectPartnerId = (state) => state[NAMESPACE]?.partnerId;
export const selectTopBannerHeight = (state) =>
  state[NAMESPACE]?.topBannerHeight;
export const selectBottomAdBannerHeight = (state) =>
  state[NAMESPACE]?.bottomAdBannerHeight;
export const selectShowPairingSuccessPage = (state) =>
  state[NAMESPACE]?.showPairingSuccessPage || false;
export const selectTuneInUserSerial = (state) =>
  state[NAMESPACE]?.tuneInUserSerial;
export const selectUserAgent = (state) => state[NAMESPACE]?.userAgent;
export const selectOneTrust = (state) => state[NAMESPACE]?.oneTrust || {};
export const selectOneTrustGdprData = (state) =>
  state[NAMESPACE]?.oneTrust?.gdpr || {};
export const selectGdprApplies = (state) =>
  selectOneTrustGdprData(state).gdprApplies ?? undefined; // value should be undefined until value is determined via OneTrust
export const selectIsGdprReady = (state) =>
  selectOneTrustGdprData(state)?.isGdprReady || false;
export const selectGdprTcString = (state) =>
  selectOneTrustGdprData(state)?.tcString || '';
export const selectCanShowBasicAdsUnderGdpr = (state) =>
  selectOneTrustGdprData(state)?.consentedPurposes?.basicAds;
export const selectCanShowPersonalizedAdsUnderGdpr = (state) => {
  const { basicAds, personalizedAdsProfile, personalizedAds } =
    selectOneTrustGdprData(state)?.consentedPurposes || {};
  return !!(basicAds && personalizedAdsProfile && personalizedAds);
};
export const selectIsOptedOutOfTargeting = (state) =>
  state[NAMESPACE]?.oneTrust?.isOptedOutOfTargeting;
export const selectCcpaData = (state) => state[NAMESPACE]?.oneTrust?.ccpa;
export const selectCcpaApplies = (state) =>
  selectCcpaData(state)?.ccpaApplies ?? undefined;
export const selectIsCcpaReady = (state) =>
  typeof selectCcpaData(state)?.ccpaApplies === 'boolean';
export const selectUsPrivacyString = (state) =>
  selectCcpaData(state)?.usPrivacyString || '';
export const selectIsBranchBannerShowing = (state) =>
  state[NAMESPACE]?.isBranchBannerShowing || false;
export const selectIsBranchInitialized = (state) =>
  state[NAMESPACE]?.isBranchInitialized || false;
export const selectIsBrazeInitialized = (state) =>
  state[NAMESPACE]?.isBrazeInitialized || false;
export const selectMwLandingBypassCookieVal = (state) =>
  state[NAMESPACE]?.mwLandingBypassCookieVal;
export const selectIsBot = (state) => state[NAMESPACE]?.isBot || false;
export const selectUserIpAddress = (state) => state[NAMESPACE]?.ipAddress;
export const selectSessionId = (state) => state[NAMESPACE]?.sessionId;
export const selectIsDiscord = (state) => state[NAMESPACE]?.isDiscord || false;
export const selectIsFord = (state) => state[NAMESPACE]?.isFord || false;
export const selectManualDiscordMode = (state) =>
  state[NAMESPACE]?.manualDiscordMode;
export const selectIsConsentReady = (state) =>
  selectIsGdprReady(state) && selectIsCcpaReady(state);
export const selectDiscordState = (state) => state[NAMESPACE]?.discord;
export const selectDiscordGeolocations = (state) =>
  state[NAMESPACE]?.discord?.activity?.participantGeolocations?.geolocations;
export const selectInfoBannerMessage = (state) =>
  state[NAMESPACE]?.infoBannerMessage;
