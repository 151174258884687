import { playerStatuses } from '../constants/playerStatuses';

const NAMESPACE = 'player';

export const selectCanScrub = (state) => state?.[NAMESPACE]?.canScrub;
export const selectShowStartEnd = (state) => state?.[NAMESPACE]?.showStartEnd;
export const selectPositionInfo = (state) =>
  state?.[NAMESPACE]?.positionInfo || {};
export const selectUserInitiatedSeekCount = (state) =>
  state?.[NAMESPACE]?.userInitiatedSeekCount;
export const selectExternalHtmlPlayerUrl = (state) =>
  state?.[NAMESPACE]?.externalHtmlPlayerUrl;
export const selectIsTunePrimed = (state) => state?.[NAMESPACE]?.isTunePrimed;
export const selectPlayerStatus = (state) => state?.[NAMESPACE]?.playerStatus;
export const selectIsIdle = (state) =>
  selectPlayerStatus(state) === playerStatuses.idle;
export const selectIsPlaying = (state) =>
  selectPlayerStatus(state) === playerStatuses.playing;
export const selectNowPlaying = (state) => state?.[NAMESPACE]?.nowPlaying || {};
export const selectNowPlayingRejectReasonKey = (state) =>
  selectNowPlaying(state)?.rejectReasonKey || '';
export const selectFollowOptions = (state) =>
  state?.[NAMESPACE]?.followOptions || {};
export const getSelectGuideIdFollowOptions = (guideId) => (state) =>
  selectFollowOptions(state)?.[guideId] || {};
export const selectNowPlayingUpsell = (state) =>
  selectNowPlaying(state)?.upsell || {};
export const selectNowPlayingClassification = (state) =>
  selectNowPlaying(state)?.classification || {};
export const selectNowPlayingAds = (state) =>
  selectNowPlaying(state)?.ads || {};
export const selectNowPlayingCanShowAds = (state) =>
  selectNowPlayingAds(state)?.canShowAds ?? true;
// the Now Playing API calls this property `canShowPrerollAds`, but we're using it for midrolls, too
export const selectNowPlayingCanShowMediaAds = (state) =>
  selectNowPlayingAds(state)?.canShowPrerollAds ?? true;
export const selectCanChangePlaybackRate = (state) =>
  state?.[NAMESPACE]?.canChangePlaybackRate || false;
export const selectPlaybackRate = (state) => state?.[NAMESPACE]?.playbackRate;
export const selectTunedGuideId = (state) =>
  state?.[NAMESPACE]?.tunedGuideId || '';
export const selectTunedBoostGuideId = (state) =>
  state?.[NAMESPACE]?.tunedBoostGuideId || '';
export const selectAutoPlayReady = (state) => state?.[NAMESPACE]?.autoPlayReady;
export const selectAutoPlayGuideItem = (state) =>
  state?.[NAMESPACE]?.autoPlayGuideItem;
export const selectIsMediaAdLoaded = (state) =>
  state?.[NAMESPACE]?.isMediaAdLoaded || false;
export const selectPlayerVolume = (state) => state?.[NAMESPACE]?.volume;
export const selectPlayerMuted = (state) => state?.[NAMESPACE]?.muted;
export const selectShowVolumeBar = (state) => state?.[NAMESPACE]?.showVolumeBar;
export const selectHasAdBlocker = (state) => state?.[NAMESPACE]?.hasAdBlocker;
export const selectGuideItemPathname = (state) =>
  state?.[NAMESPACE]?.guideItemPathname;
export const selectTuneContextLabel = (state) =>
  state?.[NAMESPACE]?.tuneContextLabel;
export const selectListenId = (state) => state?.[NAMESPACE]?.listenId;
export const selectParentGuideId = (state) => state?.[NAMESPACE]?.parentGuideId;
export const selectIsBoostStation = (state) =>
  state?.[NAMESPACE]?.isBoostStation || false;
export const selectIsBoostFeaturedInPlayer = (state) =>
  state?.[NAMESPACE]?.isBoostFeaturedInPlayer || false;
export const selectIsBoostTooltipOpen = (state) =>
  state?.[NAMESPACE]?.isBoostTooltipOpen || false;
export const selectIsIntroInProgress = (state) =>
  state?.[NAMESPACE]?.isIntroInProgress || false;
export const selectIsOutroInProgress = (state) =>
  state?.[NAMESPACE]?.isOutroInProgress || false;
export const selectNowPlayingBoost = (state) =>
  selectNowPlaying(state)?.boost || {};
export const selectBoostGuideId = (state) => state?.[NAMESPACE]?.boostGuideId;
export const selectIsTunerReady = (state) => state?.[NAMESPACE]?.isTunerReady;
